*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

:root {
  --color-primary-400: #ffdb39;
  --color-secondary-400: #00568e;
  --color-secondary-500: #004674;
  --color-gray-400: #4e4f51;
  --color-bg: white;
  --color-bg-alt: var(--color-secondary-500);
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  background-color: var(--color-bg);
  color: var(--color-gray-400);
  font-family: Montserrat, sans-serif;
}

a:any-link {
  color: var(--color-secondary-400);
}

.header_YTsiZq {
  background-color: var(--color-secondary-400);
  height: 78px;
  grid-area: header_YTsiZq;
  align-items: center;
  padding: 0 32px;
  display: flex;
}

.logo_YTsiZq {
  height: 36px;
  width: auto;
  max-width: 200px;
}

.intro_yai2iG {
  grid-area: intro_yai2iG;
  padding: 32px;
}

.text_yai2iG {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 1.8;
}

.text_small_yai2iG {
  font-size: 16px;
  line-height: 1.8;
}

.faq_yai2iG {
  background-color: var(--color-bg-alt);
  color: #fff;
  grid-area: qrcodes_yai2iG;
  padding: 32px;
}

.faqTitle_yai2iG {
  margin: 0 0 30px;
  font-size: clamp(24px, 6.8vw, 70px);
  font-weight: 600;
  line-height: 1;
}

@media (min-width: 980px) {
  .grid_yai2iG {
    height: 100%;
    grid-template: "header_yai2iG header_yai2iG"
                   "intro_yai2iG qrcodes_yai2iG" 1fr
                   / 1fr 1fr;
  }

  .faq_yai2iG, .intro_yai2iG {
    padding: 38px;
  }

  .faqTitle_yai2iG {
    margin-bottom: 40px;
  }
}

.container_5tg4bW {
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.comingSoon_5tg4bW {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
}

.comingSoonLink_5tg4bW {
  color: #fff;
  font-size: 18px;
}

.comingSoonLink_5tg4bW a {
  color: #fff;
}

.content_5tg4bW {
  height: calc(100% - 78px);
  background-color: var(--color-bg-alt);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.artifactWrap_0tQ2oa {
  justify-content: center;
  align-items: center;
  transition: opacity .25s ease-in-out;
  display: flex;
}

.artifactWrap_0tQ2oa:hover {
  opacity: .5;
}

.artifactWrap__reversed_0tQ2oa {
  flex-direction: row-reverse;
}

.artifactWrap_0tQ2oa + .artifactWrap_0tQ2oa {
  margin-top: 25px;
}

.artifactText_0tQ2oa {
  width: 200px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin: 0 20px;
  display: block;
}

.button_0tQ2oa {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.button_0tQ2oa img {
  width: 150px;
  height: 150px;
  background: #fff;
  display: block;
}

@media screen and (min-width: 1440px) {
  .artifactText_0tQ2oa {
    margin-left: 0;
  }

  .button_0tQ2oa img {
    width: 200px;
    height: 200px;
  }
}

@media screen and (min-width: 1920px) {
  .artifactText_0tQ2oa {
    width: 300px;
  }
}

.device_2IVtrG, .device_2IVtrG:before, .device_2IVtrG:after, .device_2IVtrG *, .device_2IVtrG :before, .device_2IVtrG :after {
  box-sizing: border-box;
  display: block;
}

.device_2IVtrG {
  z-index: 1;
  position: relative;
  transform: scale(.75);
}

.device_2IVtrG .device-frame_2IVtrG {
  z-index: 1;
}

.device_2IVtrG .device-content_2IVtrG {
  object-fit: cover;
  background-position: center;
  background-size: cover;
  position: relative;
}

.device-iphone-x_2IVtrG {
  height: 868px;
  width: 428px;
}

.device-iphone-x_2IVtrG .device-frame_2IVtrG {
  height: 868px;
  width: 428px;
  background: #222;
  border-radius: 68px;
  padding: 28px;
  box-shadow: inset 0 0 2px 2px #c8cacb, inset 0 0 0 7px #e2e3e4;
}

.device-iphone-x_2IVtrG .device-content_2IVtrG {
  height: 812px;
  width: 375px;
  border-radius: 40px;
}

.device-iphone-x_2IVtrG .device-stripe_2IVtrG:after, .device-iphone-x_2IVtrG .device-stripe_2IVtrG:before {
  content: "";
  height: 7px;
  width: 100%;
  z-index: 9;
  border: 0 solid #33333340;
  border-width: 0 7px;
  position: absolute;
  left: 0;
}

.device-iphone-x_2IVtrG .device-stripe_2IVtrG:after {
  top: 85px;
}

.device-iphone-x_2IVtrG .device-stripe_2IVtrG:before {
  bottom: 85px;
}

.device-iphone-x_2IVtrG .device-header_2IVtrG {
  height: 30px;
  width: 204px;
  background: #222;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-left: -102px;
  position: absolute;
  top: 28px;
  left: 50%;
}

.device-iphone-x_2IVtrG .device-header_2IVtrG:after, .device-iphone-x_2IVtrG .device-header_2IVtrG:before {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  top: 0;
}

.device-iphone-x_2IVtrG .device-header_2IVtrG:after {
  background: radial-gradient(circle at 0 100%, #0000 0 75%, #222 75% 100%);
  left: -10px;
}

.device-iphone-x_2IVtrG .device-header_2IVtrG:before {
  background: radial-gradient(circle at 100% 100%, #0000 0 75%, #222 75% 100%);
  right: -10px;
}

.device-iphone-x_2IVtrG .device-sensors_2IVtrG:after, .device-iphone-x_2IVtrG .device-sensors_2IVtrG:before {
  content: "";
  position: absolute;
}

.device-iphone-x_2IVtrG .device-sensors_2IVtrG:after {
  height: 6px;
  width: 50px;
  background: #444;
  border-radius: 3px;
  margin-left: -25px;
  top: 32px;
  left: 50%;
}

.device-iphone-x_2IVtrG .device-sensors_2IVtrG:before {
  height: 14px;
  width: 14px;
  background: #444;
  border-radius: 50%;
  margin-left: 40px;
  top: 28px;
  left: 50%;
}

.device-iphone-x_2IVtrG .device-btns_2IVtrG {
  height: 32px;
  width: 3px;
  background: #c8cacb;
  position: absolute;
  top: 115px;
  left: -3px;
}

.device-iphone-x_2IVtrG .device-btns_2IVtrG:after, .device-iphone-x_2IVtrG .device-btns_2IVtrG:before {
  content: "";
  height: 62px;
  width: 3px;
  background: #c8cacb;
  position: absolute;
  left: 0;
}

.device-iphone-x_2IVtrG .device-btns_2IVtrG:after {
  top: 60px;
}

.device-iphone-x_2IVtrG .device-btns_2IVtrG:before {
  top: 140px;
}

.device-iphone-x_2IVtrG .device-power_2IVtrG {
  height: 100px;
  width: 3px;
  background: #c8cacb;
  position: absolute;
  top: 200px;
  right: -3px;
}

.playBtn_2IVtrG {
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  display: flex;
  position: absolute;
}

.playBtn_2IVtrG.isPaused_2IVtrG, .device-iphone-x_2IVtrG:hover .playBtn_2IVtrG {
  opacity: 1;
}

.wrap__xntAq {
  color: #fff;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.title__xntAq {
  text-align: center;
  text-transform: uppercase;
  padding: 0 15px;
  font-size: 20px;
  font-weight: 400;
}

.wrap_KiRoyq {
  background: var(--color-bg-alt);
  min-height: calc(100vh - 78px);
}

.contentWrap_KiRoyq {
  min-height: calc(100vh - 78px);
  padding: 30px;
  display: flex;
  overflow: auto;
}

.contentWrap_KiRoyq > div {
  min-width: 400px;
  flex: 1;
}

/*# sourceMappingURL=index.aa559fb4.css.map */
