@import "./modern-normalize.css";

:root {
  --color-primary-400: #ffdb39;

  --color-secondary-400: #00568e;
  --color-secondary-500: #004674;

  --color-gray-400: #4e4f51;

  --color-bg: white;
  --color-bg-alt: var(--color-secondary-500);
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

body {
  background-color: var(--color-bg);
  font-family: "Montserrat", sans-serif;
  color: var(--color-gray-400);
}

a:any-link {
  color: var(--color-secondary-400);
}
