.artifactWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 250ms ease-in-out;
}

.artifactWrap:hover {
  opacity: 0.5;
}

.artifactWrap__reversed {
  flex-direction: row-reverse;
}

.artifactWrap + .artifactWrap {
  margin-top: 25px;
}

.artifactText {
  display: block;
  width: 200px;
  text-align: center;
  text-transform: uppercase;
  margin: 0 20px;
  color: white;
}

.button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.button img {
  background: white;
  display: block;
  width: 150px;
  height: 150px;
}

@media screen and (min-width: 1440px) {
  .artifactText {
    margin-left: 0;
  }

  .button img {
    width: 200px;
    height: 200px;
  }
}

@media screen and (min-width: 1920px) {
  .artifactText {
    width: 300px;
  }
}
