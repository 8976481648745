.container {
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
}

.comingSoon {
  font-size: 30px;
  font-weight: 500;
  color: white;
}

.comingSoonLink {
  color: white;
  font-size: 18px;
}

.comingSoonLink a {
  color: white;
}

.content {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 78px);
  background-color: var(--color-bg-alt);
}
