.wrap {
  background: var(--color-bg-alt);
  min-height: calc(100vh - 78px);
}

.contentWrap {
  display: flex;
  padding: 30px;
  min-height: calc(100vh - 78px);
  overflow: auto;
}

.contentWrap > div {
  flex: 1;
  min-width: 400px;
}
