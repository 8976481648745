.wrap {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  padding: 0 15px;
}
